
    import { v4 as uuidv4 } from 'uuid';
    export default {
        computed : {
            productName(){
                return this.$store.state.layout.productName ? this.$store.state.layout.productName.substring(0, 30) + '...' : null;
            },  
            paths : {
                get(){
                    var path = this.$route.path.replace(`/${this.$route.params.lang}/`,'');
                    var paths = path.split("/");
                    if(this.productName){
                        paths[paths.length-1]= this.productName
                    }
                    var results = [];
                    var url = '/'+this.$route.params.lang + ( path.includes('pages') ? '/pages' : '');
                    results.push({
                        id : uuidv4(),
                        url : this.$__url('/'),
                        name : 'breadCrumbsLabelsHome'
                    })
                    for(var p of paths){
                        if(p && p != 'pages'){
                            url += '/' + p; 
                            results.push({
                                id : uuidv4(),
                                url : url,
                                name : 'breadCrumbsLabels'+ p
                            })
                        }
                    }
                    return results;
                }
            }
        },
    }
